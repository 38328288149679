import {useCallback, useEffect, useState} from "react";
import LoadingComponent from "../Loading/LoadingComponent";
import {baseApi} from "../../api/axios";
import OrderItem from "./OrderItem";

export default function OrderSectionComponent() {
    const [status, setStatus] = useState('pending')
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1)
    const [nextPage, setNextPage] = useState(null)
    const [prevPage, setPrevPage] = useState(null)
    const nextPageNum = () => {
        setPage(page + 1)
    }
    const prevPageNum = () => {
        if (page > 1) {
            setPage(page - 1)
        }

    }
    const getOrders =async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/orders?page=${page}&status=${status}&search=`)
            if (response && response.data) {
                setOrders(response.data.orders)
                setNextPage(response.data.next)
                setPrevPage(response.data.back)
            }
        } catch (e) {
            console.log('ERROR', e.response)
        }
        setLoading(false)
    }

    useEffect(() => {
        getOrders();
    }, [page,status]);
    if (loading) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-6">
                    <LoadingComponent/>
                </div>
            </div>
        </div>
    } else {
        return <div className={'container my-4'}>
            <div className="row">
                <div className="col-12">
                    <span className="text-orange">المشتريات</span>
                </div>
                <div className="col-md-3">
                    <div className="d-flex flex-column gap-2 ">
                        <button onClick={() => setStatus('pending')}
                                className={`btn btn-sm btn-outline-info ${status === 'pending' ? 'active' : ''}`}><span>مشتريات بالإنتظار</span>
                        </button>
                        <button onClick={() => setStatus('success')}
                                className={`btn btn-sm btn-outline-success ${status === 'success' ? 'active' : ''}`}>
                            <span>مشتريات مقبولة</span>
                        </button>
                        <button onClick={() => setStatus('cancel')}
                                className={`btn btn-sm btn-outline-danger ${status === 'cancel' ? 'active' : ''}`}>
                            <span>مشتريات مرفوضة</span>
                        </button>
                    </div>
                </div>

                {orders.length > 0 ?
                    <div className="col-md-9">
                        <div className="table-responsive">
                            <table
                                className={`table table-striped order-table table-hover ${status === 'success' ? 'table-success' : ''} ${status === 'pending' ? 'table-info' : ''} ${status === 'cancel' ? 'table-danger' : ''}`}>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>المنتج</th>
                                    <th>السعر</th>
                                    <th>بيانات المشتري</th>
                                    <th>بيانات البائع</th>
                                    <th>الحالة</th>
                                    <th>وقت الشراء</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orders.length > 0 ? orders.map((el) => {
                                    return <OrderItem key={el.id} order={el}/>
                                }) : null}
                                </tbody>
                            </table>

                        </div>
                        <div className="container">
                            <div className="col-12 text-center">
                                {prevPage !== null ?
                                    <button onClick={prevPageNum} className="btn-sm submit-btn">التالي</button> : null}
                                {nextPage !== null ? <button onClick={nextPageNum}
                                                             className="btn-sm submit-btn mx-1">السابق</button> : null}


                            </div>
                        </div>
                    </div>
                    : null}
                <div className="col-md-9">  {!loading && orders.length === 0 ?
                    <h6 className={'alert alert-danger'}>لا يوجد عناصر لعرضها</h6> : null}</div>
            </div>
        </div>


    }

}